import moment from 'moment';
import 'moment/locale/de'  // without this line it didn't work
moment.locale('de')

var keywords;
var desc = 'date';
var company = 'Poolside AG';
var region = 'all';
var profession = 'all';
var type = 'all';
var current_page = 1;
var page_total;
var from = 0;
var to = 100;

function loadNextPage() {
    if (current_page < page_total) {
        current_page++;
        loadJobs();
    }
}

$('#load-more').click(function() {
    loadNextPage();
});

function loadJobs(empyTable) {

    var parameters = {
        'keywords': keywords,
        'desc': desc,
        'company': company,
        'region': region,
        'profession': profession,
        'employment_type': type,
        'workload_min': from,
        'workload_max': to,
        'page': current_page,
        'paginate': true
    };

    $.get('/api/jobs', parameters, function(data) {
        if (empyTable) {
            $('#jobs').empty();
        }

        populateJobs(data.data);
        $('#jobs_total').text('Gefundene Jobs: ' + data.total);
        page_total = data.last_page;

        if (current_page < page_total) {
            $('#load-more').show(); // Show the 'Load More' button if there are more pages
        } else {
            $('#load-more').hide(); // Hide the 'Load More' button if there are no more pages
        }
    });
}

function populateJobs(jobs) {

    if (jobs.length > 0) {
        $.each(jobs, function(index, currentJob) {

            var url = '/jobs/' + currentJob.slug;

            var jobTitle = $('<div>')
                .append('<h3 class="jobs-title">' + currentJob.title);

            var locationIcon = '<span class="fe fe-map-pin me-1" data-bs-toggle="tooltip" data-bs-placement="top" title="map-pin"></span>'
            var workloadIcon = '<span class="fe fe-clock me-1" data-bs-toggle="tooltip" data-bs-placement="top" title="clock"></span>'
            var calendarIcon = '<span class="fe fe-calendar me-1" data-bs-toggle="tooltip" data-bs-placement="top" title="calendar"></span>'

            var location = '<span class="text-gray-700 text-sm">' + locationIcon + currentJob.workplace + '</span>'
            var workload = '<span class="text-gray-700 text-sm">' + workloadIcon + currentJob.workload + '</span>'
            var begin = '<span class="text-gray-700 text-sm">' + calendarIcon + currentJob.work_begin + '</span>'

            var jobDetails = location + workload + begin

            var jobCell = $('<a class="job-cell reset-link row m-0 border border-gray-700 rounded shadow-lift mb-3 p-5" href="' + url + '" target="_blank">')
                .append(jobTitle, jobDetails);

            var jobColumn = $('<div class="col-12 col-md-6 d-flex">').append(jobCell);

            $('#jobs').append(jobColumn);
        });

    } else {
        var errorMsg = $('<h3>').text('Leider keine passende Jobs gefunden');
        $('#jobs').append(errorMsg);
    }
}

if ($('#jobs').length) {
    loadJobs();

    $('#search-keywords').on('input', function(event) {
        keywords = $(this).val();
        loadJobs(true);
    });

    $('#search-region').on("change", function (event) {
        region = $(this).val();
        loadJobs(true);
    });

    $('#search-from').on("change", function (event) {
        from = $(this).val();
        loadJobs(true);
    });

    $('#search-to').on("change", function (event) {
        to = $(this).val();
        loadJobs(true);
    });

    $('#search-profession').on("change", function (event) {
        profession = $(this).val();
        loadJobs(true);
    });

    $('#search-type').on("change", function (event) {
        type = $(this).val();
        loadJobs(true);
    });
}